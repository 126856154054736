import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Contact = () => {
  const formInitialDetails = {
    firstName: '',
    phone: '+998',
    message: ''
  };

  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Yuborish >');
  const [status, setStatus] = useState({});
  const [errors, setErrors] = useState({});

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Bo'sh inputlarni aniqlash
    const errors = {};
    if (!formDetails.firstName.trim()) {
      errors.firstName = true; // Ism bo'sh bo'lsa
    }
    if (!formDetails.phone.trim() || formDetails.phone === '+998') {
      errors.phone = true; // Telefon faqat +998 bo'lsa yoki bo'sh bo'lsa
    }
    if (!formDetails.message.trim()) {
      errors.message = true; // Xabar bo'sh bo'lsa
    }

    // Agar xatolik bo'lsa, formani yubormaslik
    if (Object.keys(errors).length > 0) {
      setErrors(errors); // Xatolarni state ga saqlaymiz
      return;
    }

    setErrors({}); // Xatolar yo'q bo'lsa
    setButtonText("Yuborilmoqda...");
    await getData();
  };

  const getData = async () => {
    let apiToken = "6242609827:AAG-x5V8O1Nq3Z3m0jOmhbL5KXG4tRK1_2U";
    let chat_id = 1110160358;
    let message = `Behruzed.uz saytidan xabar keldi: Ismi: ${formDetails.firstName}; 
Telefon raqami: ${formDetails.phone}; 
Xabar: ${formDetails.message}`;
    
    try {
      const response = await fetch(`https://api.telegram.org/bot${apiToken}/sendMessage?chat_id=${chat_id}&text=${message}`);
      if (response.status !== 200) {
        console.log('Looks like there was a problem. Status Code: ' + response.status);
        throw new Error('Failed to send message');
      }
      setStatus({ success: true, message: 'Message sent successfully' });
      window.location.replace("/xabar-yuborildi");
    } catch (error) {
      setStatus({ success: false, message: 'Something went wrong, please try again later.' });
    } finally {
      setButtonText("Yuborish >");
    }
  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Contact Us"/>
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h2 className="text-center">Qayta aloqa</h2>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col size={12} sm={6} className="px-1">
                      <input 
                        type="text" 
                        value={formDetails.firstName} 
                        placeholder="Ismingiz:" 
                        className={errors.firstName ? "red-border" : ""} 
                        onChange={(e) => onFormUpdate('firstName', e.target.value)} 
                      />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input 
                        type="text" 
                        value={formDetails.phone} 
                        placeholder="Telefon raqamingiz:" 
                        className={errors.phone ? "red-border" : ""} 
                        onChange={(e) => onFormUpdate('phone', e.target.value)} 
                      />
                    </Col>
                    <Col size={12} className="px-1">
                      <textarea 
                        rows="6"
                        value={formDetails.message} 
                        placeholder="Xabar:" 
                        className={errors.message ? "red-border" : ""} 
                        onChange={(e) => onFormUpdate('message', e.target.value)} 
                      ></textarea>
                      <div className="text-center"><button className="text-center" type="submit"><span>{buttonText}</span></button></div>
                    </Col>
                    {
                      status.message &&
                      <div>
                        <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                      </div>
                    }
                  </Row>
                </form>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}