import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import timeac from "../assets/img/timeac.jpg";
import egamelogo from "../assets/img/egame-logo.PNG";
import colorSharp2 from "../assets/img/color-sharp2.png";
import yourproject from "../assets/img/yourproject.png";
import behruzedai from "../assets/img/image.png";
import osonmarket from "../assets/img/osonmarket.jpg";
import orthodent from "../assets/img/orthodent.png";
import tashmetro from "../assets/img/tashmetro.png";
import "animate.css";
import TrackVisibility from "react-on-screen";

export const Projects = () => {
  const projects2023 = [
    {
      title: "Time Academy",
      description: "O`quv markaz uchun websayt",
      imgUrl: timeac,
      url: "https://timeacademy.uz",
    },
  ];
  const projects2024 = [
    {
      title: "E Game platformasi",
      description: "Kibersport turnirlarini avtomatlashtiruvchi tizim. № DGU 202405209/1 raqami bilan O`zbekiston Respublikasining Dasturiy mahsulotlar davlat reyestrida 09.05.2024 y. ro`yxatdan o`tkazilgan.",
      imgUrl: egamelogo,
      url: "https://egame.uz",
    },
    {
      title: "Behruzed AI",
      description: "Turli xil tarmoqlarga moslasha oladigan sun'iy intellekt. (Hozirda faqatgina Instagram sahifamda direkt xabarlariga integratsiya qilingan)",
      imgUrl: behruzedai,
      url: "https://instagram.com/behruzed",
    },
    {
      title: "osonmarket.com",
      description: "osonmarket.com internet do`koni",
      imgUrl: osonmarket,
      url: "https://osonmarket.com",
    }
  ];
  const projects2025 = [
    {
      title: "orthodent.uz",
      description: "Oliy toifadagi stomatolog websayti",
      imgUrl: orthodent,
      url: "https://orthodent.uz",
    },
    {
      title: "mkundalik.uz",
      description: "Markaziy Osiyodagi eng yirik metro - Toshkent metropoliteni davlat unitar korxonasi ichki platformasi",
      imgUrl: tashmetro,
      url: "https://mkundalik.uz",
    },
    {
      title: "Bu yerda sizning loyihangiz bo`lishi mumkin edi",
      description: "behruzed.uz",
      imgUrl: yourproject,
      url: "#",
    }
  ];

  return (
    <section className="project" id="xizmatlar">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>Xizmatlar</h2>
                  <div>
                    <ul>
                      <li className="p-1">Front-end va back-end development</li>
                      <li className="p-1">
                        Ma'lumotlar bazalari tuzish va ma'lumotlar analiz qilish
                      </li>
                      <li className="p-1">Shaxsiy CRM tizimini yaratish</li>
                      <li className="p-1">SEO optimizatsiyasi</li>
                      <li className="p-1">UX/UI web dizayn</li>
                    </ul>
                  </div>
                  <h3 className="text-center">Tugallangan loyihalar</h3>
                  <br></br>
                  <Tab.Container id="projects-tabs" defaultActiveKey="third">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">2023</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">2024</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">2025</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects2023.map((project2023, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project2023}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Row>
                        {
                          projects2024.map((project2024, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project2024}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <Row>
                        {
                          projects2025.map((project2025, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project2025}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  );
};
