import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/header-img.svg";
import { ArrowRightCircle } from "react-bootstrap-icons";
import "animate.css";
import TrackVisibility from "react-on-screen";
import behruzedai from "../assets/img/image.png";
import cv from "../documents/CVLatest.pdf";
export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState("");
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ["Web dasturchi", "UX/UI dizayner"];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [text]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex((prevIndex) => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === "") {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex((prevIndex) => prevIndex + 1);
    }
  };

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <span className="tagline">Xush kelibsiz!</span>
                  <h2 className="resph1">
                    {`Salom! men Behruz Abdurahimov`} <br></br>
                    <span
                      className="txt-rotate"
                      dataPeriod="1000"
                      data-rotate='[ "Web dasturchi", "UI/UX dizayner" ]'
                    >
                      <span className="wrap">{text}</span>
                    </span>
                  </h2>
                  <p>
                    "Men frontend va backend texnologiyalari orasida
                    mutaxassisman. Saytlarni yaratish, dizayn qilish va ularga
                    qulay va ishonchli interaktivlik qo'shishda ko'p yillik
                    tajribaga ega bo'lganman."
                  </p>
                  <a href="https://instagram.com/behruzed">
                    <button onClick={() => console.log("connect")}>
                      <img className="trybehruzedai" src={behruzedai}></img>{" "}
                      Behruzed AI ni sinab ko`ring. (Hozirda Instagram
                      direktimga o`rnatilgan)
                      <ArrowRightCircle size={25} />
                    </button>
                  </a>
                  <p className="aboutaii">
                    Behruzed AI bu turli xil tarmoqlarga moslasha oladigan
                    sun'iy intellekt hisoblanadi. Ushbu sun'iy intellekt
                    behruzed.uz tomonidan yaratilgan.
                  </p>
                </div>
              )}
            </TrackVisibility>
            <div>
              <a className="text-decoration-none" href={cv} download="CVLatest.pdf">
                <button className="downloadcv">
                  <i className="fa-solid fa-file-arrow-down"></i><br></br> <span>CV'ni yuklab
                  olish</span>
                </button>
              </a>
            </div>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__zoomIn" : ""
                  }
                >
                  <img src={headerImg} alt="Header Img" />
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
