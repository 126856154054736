import { useState, useEffect } from "react";
import { Col, Row, Alert } from "react-bootstrap";
import insta from "../../src/assets/img/insta.avif"
export const Newsletter = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (status === 'success') clearFields();
  }, [status])

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
    email.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email
    })
  }

  const clearFields = () => {
    setEmail('');
  }

  return (
      <Col lg={12}>
        <div className="newsletter-bx wow slideInUp">
          <Row>
            <Col lg={12} md={6} xl={5}>
              <h3>Instagram sahifamga obuna bo`ling<br></br> & Yangiliklarni o`tkazib yubormang...</h3>
              {status === 'sending' && <Alert>Sending...</Alert>}
              {status === 'error' && <Alert variant="danger">{message}</Alert>}
              {status === 'success' && <Alert variant="success">{message}</Alert>}
            </Col>
            <Col md={6} xl={7}>
              <div className="text-center instaback">
                <a target="blank" href="https://instagram.com/behruzed">
                <img src={insta}></img>
                <h5>@behruzed</h5>
                </a>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
  )
}
